import React, { Component, createContext } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import asyncComponent from './components/asyncComponent';
import CitySelection from './components/CitySelection';
import Logo from './media/images/logo_header.png'
import { Redirect } from 'react-router-dom';

const Home = asyncComponent(() => {
  return import('./components/Home');
})
const Header = asyncComponent(() => {
  return import('./components/Header');
})
const Footer = asyncComponent(() => {
  return import('./components/Footer');
})
const Prices = asyncComponent(() => {
  return import('./components/Prices');
})
const SmallChef = asyncComponent(() => {
  return import('./components/SmallChef');
})
const Plasticity = asyncComponent(() => {
  return import('./components/Plasticity');
})
const Rhythmicity = asyncComponent(() => {
  return import('./components/Rhythmicity');
})
const Senso = asyncComponent(() => {
  return import('./components/Senso');
})
const FunOnTheAir = asyncComponent(() => {
  return import('./components/FunOnTheAir');
})
const Contact = asyncComponent(() => {
  return import('./components/Contact');
})
const NotFound = asyncComponent(() => {
  return import('./components/NotFound');
})
const Rodo = asyncComponent(() => {
  return import('./components/Rodo');
})
const Documents = asyncComponent(() => {
  return import('./components/Documents');
})

const CoFinancedEUFunds = asyncComponent(() => {
  return import('./components/CoFinancedEUFunds');
})
export const CityContext = createContext('');
export const HeaderContext = createContext('');
export const HomeContext = createContext('');
export const CosContext = createContext('');
class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedCity: '',
    };

  }

  componentDidMount() {
    const selectedCity = sessionStorage.getItem('selectedCity');
    if (selectedCity) {
      this.setState({ selectedCity });
    }
  }

  setCity = (city) => {
    city && this.setState({ selectedCity: city });
    city && sessionStorage.setItem('selectedCity', city);
  }

  render() {
    const { selectedCity } = this.state;

    const current_url = window.location.pathname;

    // Sprawdzenie, czy miasto zostało wybrane
    if (!selectedCity && current_url !== '/pila/maluch-plus' && current_url !== '/pila' && current_url !== '/pila/maluch-plus') {
      return (
        <CityContext.Provider value={this.setCity}>
          <CitySelection setCity={this.setCity} />
        </CityContext.Provider>
      );
    }
    return (
      <div>
        <div>
          <HeaderContext.Provider value={this.setCity}>
            <Header imgSrc={Logo} selectedCity={selectedCity} setCity={this.setCity} />
          </HeaderContext.Provider>

          <Router>
            <Switch>
              <Route exact path="/pila" render={() =>
                <HomeContext.Provider value={this.setCity}>
                  <Home selectedCity={'pila'} setCity={this.setCity} />
                </HomeContext.Provider>

              } />
              <Route exact path="/szczecin" render={() =>
                <HomeContext.Provider value={this.setCity}>
                  <Home selectedCity={'szczecin'} setCity={this.setCity} />
                </HomeContext.Provider>

              } />

              {selectedCity === 'szczecin' && (
                <Route exact path="/" render={() => <Redirect to="/szczecin" />} />
              )}
              {selectedCity === 'pila' && (
                <Route exact path="/" render={() => <Redirect to="/pila" />} />
              )}

              {selectedCity === 'szczecin' && (
                <Route path="/pila/maluch-plus" render={() => <Redirect to="/szczecin" />} />
              )}

              <Route exact path="/pila/maluch-plus" render={() =>
                <CosContext.Provider value={this.setCity}>
                  <CoFinancedEUFunds selectedCity={'pila'} setCity={this.setCity} />
                </CosContext.Provider>
              } />

              <Route
                exact
                path="/"
                render={() => (<Home selectedCity={selectedCity} setCity={this.setCity}/>)}
              />


              <Route path="/cennik" render={() => <Prices selectedCity={selectedCity} />} />
              <Route path="/mali-kucharze" render={() => <SmallChef selectedCity={selectedCity} />} />
              <Route path="/plastyka" render={() => <Plasticity selectedCity={selectedCity} />} />
              <Route path="/rytmika" render={() => <Rhythmicity selectedCity={selectedCity} />} />
              <Route path="/sensoplastyka" render={() => <Senso selectedCity={selectedCity} />} />
              <Route path="/klauzula-informacyjna" render={() => <Rodo selectedCity={selectedCity} />} />
              <Route path="/dokumenty" render={() => <Documents selectedCity={selectedCity} />} />
              <Route path="/zabawy-na-powietrzu" render={() => <FunOnTheAir selectedCity={selectedCity} />} />
              <Route path="/kontakt" render={() => <Contact selectedCity={selectedCity} />} />




              <Route component={NotFound} />
            </Switch>
          </Router>
        </div>
        <Footer imgSrc={Logo} selectedCity={selectedCity} />
      </div>
    );
  }
}

export default App;
