import React, { Component } from 'react'

import '../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../css/style.css'



class CitySelection extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedCity: '',
    }
  }

  handleCityChange = event => {
    const selectedCity = event;
    this.setState({
      selectedCity,
    });

    sessionStorage.setItem('selectedCity', selectedCity)

    this.props.setCity(selectedCity);
  }

  handleSubmit = event => {
    event.preventDefault()
    const { selectedCity } = this.state
    sessionStorage.setItem('selectedCity', selectedCity)

    this.props.setCity(selectedCity);
  }

  render() {
    return (
        <div className="city-selection__container">
        <div>
        <label htmlFor="city">
          Wybierz miasto placówki, która Cię inetesuje
        </label>
        <button className="city-selection__button" type="button" onClick={() => this.handleCityChange('szczecin')}>
              SZCZECIN
            </button>
            <button className="city-selection__button" type="button" onClick={() => this.handleCityChange('pila')}>
              PIŁA
            </button>

        </div>
      </div>
    )
  }
}

export default CitySelection
